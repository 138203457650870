<template>
	<div class="page-content">
		<page-breadcrumb v-if="item" title="Reward Detail" class="mb-2" :items="breadcrumbItems" />
		<section class="mb-2">
			<b-card class="mt-1" v-if="item">
				<form-generator :model="item" :schema="item_form_schema" />
			</b-card>
		</section>
		<action-buttons update-button back-button delete-button @update="update" :is-updating="isUpdating"
			@delete="deleteItem" :is-deleting="isDeleting" 
		/>
	</div>
</template>
<script>
const item_form_schema = [
	{
		cols: 4,
		fields: [
			{ label: 'Image', field: 'image', input_type: 'image' },
		]
	},
	{
		cols: 8,
		fields: [
			{ label: 'Name', field: 'name', validate: { required: true } },
			{ label: 'Type', field: 'type', validate: { required: true } },
		]
	}
];
import service from '../service'
export default {
	data() {
		return {
			item_form_schema,
			item: null,
			isUpdating: false,
			isDeleting: false,
		}
	},
	computed: {
		itemId() {
			return this.$route.params.id;
		},
		breadcrumbItems() {
			let items = [
				{
					text: 'Reward',
					to: { name: 'reward-list' },
				},
				{
					text: `${this.item ? this.item.title : this.itemId}`, active: true
				},
			]
			return items
		},
		authLanguage() {
			return this.$store.getters["auth/language"];
		},
	},
	created() {
		this.getDetail()
	},
	methods: {
		async getDetail() {
			this.item = await service.get({ id: this.itemId });
		},
		async update() {
			this.isUpdating = true;
			let data = { ...this.item };
			await service.update({
				data: JSON.stringify(data),
			});
			this.getDetail();
			this.isUpdating = false;
		},
		async deleteItem() {
			await service.delete({ id: this.itemId });
			this.$router.push({ name: "reward-list" });
		},
	}
}
</script>